<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 g c">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-edit"></i>
            {{ e("manual-attend") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">{{ e("select-input-date") }}</label>
            <input type="datetime-local" class="form-control" v-model="date" />
          </div>
          <div class="row g">
            <div class="col-12">
              {{ e("select-status") }}
            </div>
            <div class="col-12 col-lg-4 g text-center">
              <button
                @click="
                  status = 2;
                  recheckStatuses();
                "
                :class="
                  'btn btn-block ' +
                  (status == 2 ? 'btn-success' : 'btn-outline-primary')
                "
                style="border-radius: 0px"
              >
                <i class="fa fa-sign-in"></i>
                {{ e("attend") }}
              </button>
            </div>
            <div class="col-12 col-lg-4 g text-center">
              <button
                @click="
                  status = 3;
                  recheckStatuses();
                "
                :class="
                  'btn btn-block ' +
                  (status == 3 ? 'btn-warning' : 'btn-outline-primary')
                "
                style="border-radius: 0px"
              >
                <i class="fa fa-clock"></i>
                {{ e("late") }}
              </button>
            </div>
            <div class="col-12 col-lg-4 g text-center">
              <button
                @click="
                  status = 5;
                  recheckStatuses();
                "
                :class="
                  'btn btn-block ' +
                  (status == 5 ? 'btn-info' : 'btn-outline-primary')
                "
                style="border-radius: 0px"
              >
                <i class="fa fa-sign-out"></i>
                {{ e("out") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-10 g c">
      <div class="card card-body" v-if="status != 0">
        <div class="col-12 text-center g">
          <button class="btn btn-success" @click="sendNow()">
            {{ e("add-now") }}
          </button>
        </div>
        <div class="col-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <th>
                {{ e("name") }}
              </th>
              <th>
                {{ e("number") }}
              </th>
              <th>
                {{ e("select") }}
                <button class="btn btn-sm btn-primary" @click="all()">
                  <i class="fa fa-check-square"></i> {{ e("all") }}
                </button>
              </th>
            </thead>
            <tbody>
              <tr v-for="member in members" :key="member._id">
                <td>
                  {{ member.name }}
                </td>
                <td>
                  {{ member.number }}
                </td>
                <td>
                  <!-- switch -->
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      class="custom-control-success"
                      name="check-button"
                      v-model="selected"
                      :value="member.number"
                      inline
                      :disabled="primarySelected.includes(member.number)"
                    >
                      <strong> {{ e("select") }}</strong>
                    </b-form-checkbox>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueDatetimeJs from "vue-datetime-js";
import Ripple from "vue-ripple-directive";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
import {
  BOverlay,
  VBModal,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    datePicker: VueDatetimeJs,
    VBModal,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
      hijri: false,
      status: 0,
      members: [],
      selected: [],
      primarySelected: [],
      reports: {},
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    this.date =
      moment()
        .tz(
          this.user.time_zone != "" && this.user.time_zone
            ? this.user.time_zone
            : "Africa/Cairo"
        )
        .format("YYYY-MM-DD H:mm:ss")
        .split(":")[0]
        .split(" ")[1]
        .split("").length == 1
        ? moment()
            .tz(
              this.user.time_zone != "" && this.user.time_zone
                ? this.user.time_zone
                : "Africa/Cairo"
            )
            .format("YYYY-MM-DD 0H:mm:ss")
        : moment()
            .tz(
              this.user.time_zone != "" && this.user.time_zone
                ? this.user.time_zone
                : "Africa/Cairo"
            )
            .format("YYYY-MM-DD H:mm:ss");
    var g = this,
      _g = this;
    $.post(api + "/users/index-all", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.members = JSON.parse(r).response;
        g.getReportToday();
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    sendNow() {
      var g = this;
      g.loading = true;

      $.post(api + "/reports/manual-attend", {
        jwt: g.user.jwt,
        status: g.status?.toString(),
        selected: JSON.stringify(g.selected),
        time: g.date,
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.selected = [];
            g.status = 0;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    getReportToday() {
      var g = this,
        _g = g;
      $.post(api + "/reports/day", {
        jwt: g.user.jwt,
        date: g.date?.split(" ")[0],
        filter: { type: null },
        number: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response.code),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            _g.reports = r.response.arr;
          }
          _g.loading = false;
        })
        .catch((ea) => {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error") + ea,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    recheckStatuses() {
      var g = this,
        _g = g;
      g.selected = [];
      g.primarySelected = [];
      g.loading = true;
      $.post(api + "/reports/day", {
        jwt: g.user.jwt,
        date: g.date?.split(" ")[0],
        filter: { type: null },
        number: window.location.href.split("number=").length
          ? window.location.href.split("number=")[1]
          : null,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response.code),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            _g.reports = r.response.arr;
            for (const number in g.reports) {
              for (const report in g.reports[number].reports) {
                var ok = false;
                if (
                  (report == "status_3" && g.status == "2") ||
                  (report == "status_2" && g.status == "3")
                ) {
                  ok = true;
                } else {
                  ok = false;
                }
                if (report == "status_" + g.status || ok) {
                  g.selected.push(number);
                  g.primarySelected.push(number);
                }
              }
            }
          }
          _g.loading = false;
        })
        .catch((ea) => {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error") + ea,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
    all() {
      var g = this;
      g.selected = [];
      g.members.forEach(function (m) {
        g.selected.push(m.number);
      });
    },
  },
};
</script>
<style>
.custom-control-label:hover {
  cursor: pointer !important;
  color: green;
}
</style>